$(document).ready(function(){
    $(document).on('click', '.read-more-block', function() {
        // Get parent .content-row element
        var $this = $(this).parents('.content-row').eq(0);
        // If parent is not already active
        if (!$this.hasClass('active')) {
            // Add .active, add .active to cross, back to .content-rows, get all siblings, add .inactive and 'left' or 'right', depending on odd or even
            $this.addClass('active').find('.cross').addClass('active').end().siblings().addClass('inactive ' + ($this.index() % 2 ? 'right' : 'left'))
            setTimeout(function () {

                //tonen andere p
                $this.find('.text p, .text img, .text .news-slider, .share-article').show();
                var $getHeightH2 = $this.find('h2').height();
                var $getHeightText = $this.find('.text').height();
                var $getHeightSocial = $this.find('.share-article').height();
                var $getHeight = $getHeightText + $getHeightH2 + $getHeightSocial + 200;
                $this.find('.content-text').css('height', $getHeight);

                $this.find('.content-text').css('padding-bottom', '0')
                if($('.content-row').hasClass('active')){
                    $('.pagination').css('opacity','0')
                }
            }, 1300)
            $('html, body').animate({
                scrollTop: $this.offset().top
            }, 700);
        } else {
            $this.find('.cross').removeClass('active');

            $this.find('.text p:not(:first-child), .text img, .text .news-slider, .share-article').hide();

            // Give .content-text a height of 100% again, as opposed to the actual text height
            $this.find('.content-text').css('height', '100%')
            // $this.find('.content-text').css('border-bottom-width', '200px')
            setTimeout(function () {
                // After 1 second, remove .active, .inactive, .right and .left of all elements, resetting everything to normal
                $this.removeClass('active').siblings().removeClass('inactive right left')
                $('.pagination').css('opacity','1')
            }, 700)
        }
    });

    //mobile

    var parBegin = $('.text').height();

    $(document).on('click', '.mobileReadMore', function() {

        var $this = $(this).parents('.content-row').eq(0);
        $this.find('.text p, .text img, .share-article').show();

        var par = $this.find('.text')[0].scrollHeight
        if(!$this.find('.text').hasClass('active')) {

            $this.find('.text').css('height', par).end()
                .find('.text').addClass('active').end()
                .find('.openText').addClass('active').end()
                .find('.closeText').addClass('active').end();
        } else {
            $this.find('.text p:not(:first-child), .text img, .share-article').hide();
            $this.find('.text').css('height', parBegin).end()
                .find('.text').removeClass('active').end()
                .find('.openText').removeClass('active').end()
                .find('.closeText').removeClass('active').end();
            $('html, body').animate({
                scrollTop: $this.offset().top
            }, 700);
        }
    });

})
