$(document).ready(function () {
    // tonen van meer informatie algemene voorwaarden
    // verwijderen van meer informatie button
    $('#generalterms .more-info').on('click', function () {
        $(this).css('display', 'none');
        $(this).parent().next('.generalterms-read-more').css('display', 'block');
    });

    // actief niet actief ivm het pijltje en kruisje bij de titel
    $('#generalterms .panel-collapse').on('show.bs.collapse', function () {
        $(this).siblings('.panel-heading').addClass('active');
    });
    $('#generalterms .panel-collapse').on('hide.bs.collapse', function () {
        $(this).siblings('.panel-heading').removeClass('active');
    });
});