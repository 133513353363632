/**
 * Function to check if all filters is checked.
 */
$(function () {

    $("#combo-categories").on('change', function () {

        var uri = window.location.toString();

        cleanUrl(uri);

        $('#form-filter-news').submit();

    });
});

function cleanUrl(uri) {

    if (uri.indexOf("?") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
    }
}


$('ul.dropdown-menu li').click(function(e)
{
    $('#category_id').val($(this).attr('data-value'));

    $('#form-filter-downloads').submit();
    $('#form-filter-news').submit();
});