
var options = {
    selectorAttribute: "data-target",
    backToTop: true
};

$('.nav-tabs').stickyTabs(options);

$('.nav-tabs li > a').click(function (event) {

    $( ".tab-content div" ).each(function() {
        $( this ).removeClass( "active" );
    });

    $('.tab-content #' + $(this).attr('data-target')).addClass('active');
});


$('.practice-equipment li > a').click(function (event) {
    $( ".practice-container .tab-content div" ).each(function() {
        $( this ).removeClass( "active" );
    });

    $('.practice-container .tab-content ' + $(this).attr('data-target')).addClass('active');

});
