$(document).ready(function () {

    var $formErrorsContainer = $('#download-form-errors'),
        $downloadsModal = $('#downloadsModal'),
        $downloadsForm = $("#form-download"),
        $downloadModalLink = $('.download-links'),
        $downloadModalLinkSelector = '.download-modal-link';

    $downloadModalLink.on('click', $downloadModalLinkSelector, function () {
        $formErrorsContainer.html('');
        $downloadsModal.modal();

        $('#download_id').val($(this).attr('data-id'));

        $downloadsForm.trigger("reset");
    });

    $('#form-download button.download-submit').on('click', function () {
        $(this).request('onValidateDownloadForm', {
            success: function (data) {
                if (data.errors) {
                    return $formErrorsContainer.html(data.errors);
                }

                if (data.success === true) {

                    $formErrorsContainer.html(data.view);
                    $downloadsForm.trigger("reset");

                    setTimeout(function () {
                        $downloadsModal.modal('hide');
                    }, 3500);

                    return true;
                }

            }
        });

        return false;
    });


    var $newsLetterModal = $('#newsletterModal');

    $('.newsletter-modal-link').on('click', function (event) {
        $('#newsletter-form-errors').html('');

        $newsLetterModal.modal();

        $("#form-newsletter").trigger("reset");

        event.preventDefault();

    });

    $('#form-newsletter button.newsletter-submit').on('click', function () {
        $(this).request('onValidateNewsLetterSignup', {
            success: function (data) {
                if (data.errors) {
                    return $('.modal-body #newsletter-form-errors').html(data.errors);
                }

                $newsLetterModal.modal('hide');
            }
        });

        return false;
    });

    // products same height
    $windowWidth = $(window).width();

    if ($windowWidth > 753) {

        var $infoBlockHeights = $('.info-block').map(function () {
                return $(this).height();
            }).get(),
            $maxHeightInfoBlock = Math.max.apply(null, $infoBlockHeights);

        $maxHeightInfoBlock = $maxHeightInfoBlock + 115;

        $('.info-block').css('height', $maxHeightInfoBlock);
    }


    var $parts = location.href.split('#')

    if ($parts.length > 1) {

        var $part1 = $parts[0], $part2 = $parts[1], $isNewsPage;

        $isNewsPage = $part1.indexOf('nieuws') !== -1;

        if ($isNewsPage && $part2 == 'aanmelden') {
            $newsLetterModal.modal();
        }
    }

});
