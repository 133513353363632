//
$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 150) {
            $('#page-contact .navbar').addClass('change-color');
        }
        if ($(this).scrollTop() < 150) {
            $('#page-contact .navbar').removeClass('change-color');
        }
    });

});
