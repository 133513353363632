//
$(document).ready(function () {

    if(window.location.pathname=='/'){
        $('body').addClass('home')
    }
    else {
        $('body').addClass('page')
    }
    $('.overlay-video').click(function(){
        var video = $('#video').get(0);
        if (video.paused === false) {
            video.pause();
        } else {
            video.play();
        }
    });

    $(".btn-download-tv").click(function(){
        $(".hide-text").show();
    });

});
