var $hamburger = $('[data-identifier="hamburger"]'),
    $menuSlideRight = $('[data-identifier="menu-slide-right"]'),
    $navigation = $('.navigation');

$hamburger.on("click", function (event) {


    $hamburger.toggleClass("is-active");
    $menuSlideRight.toggleClass("active");
    $navigation.removeClass('close-nav');

    $(".page-overlay").fadeToggle("slow", "linear");

    // close navigation & sub-navigation
    var $isActive = $hamburger.hasClass('is-active');


    if (!$isActive) {

        var closeNavigation = function () {
            $navigation.removeClass('open-nav');    // sluit het sub menu
            $navigation.toggleClass('close-nav');   // sluit het hoofd menu
        };

        setTimeout(closeNavigation, 100);

    }

    if (isMobile()) {
        resetMobileNavigation();
    }

    event.preventDefault();

});

$('.navigation ul > li > a')
    .each(function () {
        if ($(this).closest("li").children("ul").length) {
            // the clicked on <li> has a <ul> as a direct child
            $(this).toggleClass("changed")
        }
    })
    .click(function () {

        var $childrenLength = $(this).closest("li").children("ul").length;

        // mobile version
        if (isMobile()) {

            if ($childrenLength) {
                $unordenedList = $(this).closest("li").children("ul");
                $unordenedList.addClass('active');

            }

            return;
        }


        // desktop version
        if ($childrenLength) {

            // reset childs
            $(this).each(function () {
                $(".sub-navigation").css('display', 'none');
                $(".sub-navigation").removeClass("active");
            });

            // check if subnav already open
            if (!$navigation.hasClass('open-nav')) {
                $navigation.addClass('open-nav');
            }

            $(this).closest("li").children("ul").css('display', 'block');
        }
    });


// Remove mobile active subnavigation
$('.sub-navigation > a').click(function () {
    $(this).parent().removeClass('active');
});

function resetMobileNavigation() {
    $('.navigation li > .sub-navigation').removeClass('active');
};

function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

var $getHeightNavBar = $(".navbar").height();
$('#layout-content').css('margin-top', $getHeightNavBar);
$('#page-home #layout-content').css('margin-top', '0');
$('#page-contact #layout-content').css('margin-top', '0');

// change logo open menu
var $hamburger = $('[data-identifier="hamburger"]');

$hamburger.on("click", function (event) {
    var $navigation = $('.navigation'),
        $isActive = $navigation.hasClass('active'),
        $body = $('html'),
        $bodyHome = $body.find("#page-home"),
        $bodyContact = $body.find("#page-contact");

    // If subnav is true and home is true, then don't show the navbar-overlay
    if (($isActive === true) && ($bodyHome.length > 0)) {
        $('.navbar-overlay').css('display', 'none');
    }

    // If subnav and contact is true is true, then don't show the navbar-overlay
    // If subnav and contact is true is true, then toggle logo color
    else if (($isActive === true) && ($bodyContact.length > 0)) {

        $('.navbar-overlay').css('display', 'none');
        $('#logo-home img').css('display', 'block');
        $('#logo-color img').css('display', 'none');
    }

    // If subnav is true but home and contact is false, then show the navbar-overlay
    // If subnav is true but home and contact is false, then toggle logo color
    else if (($isActive === true) && (($bodyHome.length <= 0) && ($bodyContact.length <= 0) )) {

        $('.navbar-overlay').fadeIn("slow");
        $('#logo-home img').css('display', 'block');
        $('#logo-color img').css('display', 'none');
    }

    // If subnav is false and home is true, then leave logo color on white
    else if (($isActive === false) && ($bodyHome.length > 0)) {

        $('#logo-home img').css('display', 'block');
        $('#logo-color img').css('display', 'none');
    }

    // If subnav is false and home is false, then fade out navbar-overlay
    // If subnav is false and home is false, then change logo color to purple
    else if (($isActive === false) && ($bodyHome.length <= 0)) {

        $('.navbar-overlay').fadeOut("slow");
        $('#logo-home img').css('display', 'none');
        $('#logo-color img').css('display', 'block');
    }
});
