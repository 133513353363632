var popupMeta = {
    width: 400,
    height: 400
};

$(document).on('click', '.social-share', function (event) {

    event.preventDefault();

    var vPosition = Math.floor(($(window).width() - popupMeta.width) / 2),
        hPosition = Math.floor(($(window).height() - popupMeta.height) / 2);

    var url = $(this).attr('href');
    var popup = window.open(url, 'Social Share',
        'width=' + popupMeta.width + ',height=' + popupMeta.height +
        ',left=' + vPosition + ',top=' + hPosition +
        ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');

    if (popup) {
        popup.focus();
        return false;
    }
});


$(document).on('click', '.read-more-block', function () {

    var $this = $(this).parents('.content-row').eq(0);

    var $dataOgTitle = $(this).attr('data-og-title'),
        $dataOgUrl = $(this).attr('data-og-url');

    if (!$this.hasClass('active')) {

        var $slug = $(this).attr('data-slug'),
            $post_id = $(this).attr('data-id');

        setLocationHash($slug);
        setOgTags($post_id);

    } else {

        cleanupUri();
        resetOgTags($dataOgTitle, $dataOgUrl);
    }

});

function resetOgTags($ogTitle, $dataOgUrl, $dataOgImage){

    var $ogTags = {'title': $ogTitle, 'url' : $dataOgUrl, 'image': '' };

    $.each( $ogTags, function( key, value ) {
        $('meta[property="og:' + key + '"]').attr('content', value);
    });
}

function setLocationHash($slug){

    return location.hash = '#' + $slug;
}

function setOgTags($post_id){

    $.request('onSetOgTags',  {
        data: {id: $post_id},
        success: function (data) {

            $.each( data, function( key, value ) {
                $('meta[property="og:' + key.substr(3) + '"]').attr('content', value);
            });
        },
        error: function (data) {
            console.log('Error !', data);
        }
    });
}

function cleanupUri(){

    window.location.href.split('#')[1];
    window.location.href.substr(1, window.location.href.indexOf('#'));
    history.pushState("", document.title, window.location.pathname + window.location.search);
}





var parts = location.href.split('#');

if (parts.length > 1) {
    var part1 = parts[0], part2 = parts[1], isNewsPage = part1.indexOf('nieuws') !== -1;

    if (isNewsPage) {

        $(".read-more-block").each(function (index) {

            var $this = $(this).parents('.content-row').eq(0);

            if ($(this).attr('data-slug') == part2) {

                if (!$this.hasClass('active')) {
                    // Add .active, add .active to cross, back to .content-rows, get all siblings, add .inactive and 'left' or 'right', depending on odd or even
                    $this.addClass('active').find('.cross').addClass('active').end().siblings().addClass('inactive ' + ($this.index() % 2 ? 'right' : 'left'))
                    setTimeout(function () {

                        //tonen andere p
                        $this.find('.text p, .text img, .text .news-slider, .share-article').show();
                        var $getHeightH2 = $this.find('h2').height();
                        var $getHeightText = $this.find('.text').height();
                        var $getHeightSocial = $this.find('.share-article').height();
                        var $getHeight = $getHeightText + $getHeightH2 + $getHeightSocial + 200;
                        $this.find('.content-text').css('height', $getHeight);

                        $this.find('.content-text').css('padding-bottom', '0')
                        if ($('.content-row').hasClass('active')) {
                            $('.pagination').css('opacity', '0')
                        }
                    }, 1300)
                    $('html, body').animate({
                        scrollTop: $this.offset().top
                    }, 700);
                } else {
                    $this.find('.cross').removeClass('active');

                    $this.find('.text p:not(:first-child), .text img, .text .news-slider, .share-article').hide();

                    // Give .content-text a height of 100% again, as opposed to the actual text height
                    $this.find('.content-text').css('height', '100%')
                    // $this.find('.content-text').css('border-bottom-width', '200px')
                    setTimeout(function () {
                        // After 1 second, remove .active, .inactive, .right and .left of all elements, resetting everything to normal
                        $this.removeClass('active').siblings().removeClass('inactive right left')
                        $('.pagination').css('opacity', '1')
                    }, 700)
                }
            }
        });
    }
}


