/**
 * Function to check if all filters is checked.
 */

$(function () {

    $(".dropdown-menu").on("click", "li", function (event) {

        var uri = window.location.toString();

        cleanUrl(uri);

        $('#download_category_id').val($(this).attr('data-value'));
        $('#form-filter-downloads').submit();
        $('#downloads_filter_list > li').removeClass('active');
        $(this).addClass('active');

    });

});

function cleanUrl(uri) {

    if (uri.indexOf("?") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
    }
}
