$(window).load(function () {
    // gelijke hoogte instellen team
    var $heightPerson = $('.team-person').map(function () {
        return $(this).width();
    }).get();

    $maxHeightPerson = Math.max.apply(null, $heightPerson) - 1;

    $('.team-person').css(
        {
            'height': $maxHeightPerson
        }
    );

    // hoogte overlay instellen
    var $overlay = $('.person-overlay');
    $overlay.each(function (index, element) {
        var $thisOverlayHeight = $(this).height();
        var $overlayHeight = $maxHeightPerson - 20;

        var $multiplyHeight = Math.ceil($thisOverlayHeight / $overlayHeight);

        var $newOverlayHeight = ($maxHeightPerson * $multiplyHeight) - 20;

        $(this).css(
            {
                'min-height': $newOverlayHeight
            }
        );
    });

    // tonen van overlay bij mouse over / click
    // sluit eerst alle overlays
    // open daarna het geselecteerde overlay
    $('.team-person').on('click mouseover', function () {
        $('.person-overlay').css('display', 'none');
        var $clickable = $(this).hasClass('clickable');

        if($clickable) {
            $(this).children('.person-overlay').css(
                {
                    'display': 'block'
                }
            );
        }
    });

    // bij mouse leave alle overlay sluiten
    $('.person-overlay').on('mouseleave', function () {
        $('.person-overlay').css('display', 'none');
    });

    //overlay sluiten op mobile device
    $('.close_person_overlay').on('click',function () {
        var $el = $(this);
        $(this).parent().parent().parent('.team-person').removeClass('clickable');
        $('.person-overlay').css('display', 'none');

        setTimeout(function(){
            $el.parent().parent().parent('.team-person').addClass('clickable');
        }, 1000);
    });
});